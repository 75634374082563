import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { PATHS } from '~/constants/router'

const _404 = () => {
  const router = useRouter()

  useEffect(() => {
    // Always navigate to SSR-rendered `/not-found` page after the first render
    router.push(PATHS.NOT_FOUND, undefined, { shallow: true })
  }, [router])

  return <></>
}

export default _404
